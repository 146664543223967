<template>
    <div style="background-color: #f8f9fc">
        <headComponent>
            <template #head>
                <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
            </template>
        </headComponent>

        <el-breadcrumb separator="/" style="margin: 24px auto 0;width: 83%;">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/exam' }">模拟考试记录</el-breadcrumb-item>
            <el-breadcrumb-item>模拟考试报告</el-breadcrumb-item>
        </el-breadcrumb>

        <div style="width: 83%; height: 743px;margin: auto;margin-top: 50px;border-radius: 12px;overflow: hidden;box-shadow: 0px 8px 14px 0px #0000000F;
">
            <div
                style="width: 100%;height: 91px;background: linear-gradient(to right, #4886FF26, #95BAFF0A);position: relative;overflow: hidden;">
                <div style="position: absolute;top: 23px;left: 33px;">
                    <span style="color: #377BFE;font-size: 32px;">模拟考试报告</span>
                </div>
            </div>

            <!-- 左 -->
            <div style="clear: both;">
                <div style="display: flex;height: 652px;overflow: hidden;">
                    <div style="width: 310px;height: 652px;background-color: #FFFFFF;border-right: 1px solid #E6E6E6">

                        <div style="margin-top: 40px;margin-left: 38px;">
                            <span style="color: #FBA90A;font-size: 17px; font-weight: 400">个人信息</span>
                        </div>

                        <div style="padding-top:7px;display: flex; align-items: center;">
                            <span style="color: #7B828D; font-size: 15px;margin-left: 38px;">
                                姓名
                            </span>


                            <span style="font-size: 15px;color: #121925;margin-left: 40px;">
                                {{ this.my.name }}
                            </span>
                        </div>


                        <div style="padding-top:7px;display: flex; align-items: center;">

                            <span style="color: #7B828D; font-size: 15px;margin-left: 38px;">
                                科室
                            </span>


                            <span style="font-size: 15px;color: #121925;margin-left: 40px;">
                                {{ this.my.orgName }}
                            </span>

                        </div>
                        <hr style="width: 258px;top: 200px;left: 284px;border-top:1px solid #E6E6E6;margin-top: 56px;">

                        <div style="margin-top: 46px;">
                            <span style="font-size: 17px;color: #FBA90A;margin-left: 38px;">本次模拟考试得分</span>
                        </div>

                        <!-- 统计图 -->
                        <div ref="echartsContainer"
                            style="width: 194px; height: 194px;padding-left: 73px;padding-top:29px;">
                        </div>
                        <br>
                        <div style="padding-top:7px;">
                            <span style="color: #7B828D; font-size: 15px;margin-left: 38px;width: 67px">
                                题目
                            </span>
                            <span style="font-size: 15px;color: #121925;margin-left: 49px;">
                                {{ this.examResp.courseName }}
                            </span>
                        </div>
                        <div style="padding-top:7px;">
                            <span style="color: #7B828D; font-size: 15px;margin-left: 38px;width: 67px">
                                考试得分
                            </span>
                            <span style="font-size: 15px;color: #121925;margin-left: 19px;">
                                {{ this.examResp.eu.score }}/{{ this.examResp.exam.score }}
                            </span>
                        </div>

                    </div>


                    <!-- 中 -->
                    <div style="width: 365px;height: 652px;background-color: #FFFFFF;border-right: 1px solid #E6E6E6">

                        <div style="margin-top: 42px;margin-left: 29px;display: flex;">
                            <img src="../../assets/exam/Group (7).png">
                            <div v-if="this.differenceScore < 0" style="margin-left: 4px;display: flex;width: 100%;">
                                <span style="">您比其他考生平均正确率低</span><span style="padding-left: 6px;color: #F46363;">
                                    {{ this.differenceScore * -1 }}%
                                </span>
                                <img src="../../assets/exam/heroicons-solid_trending-down.png"
                                    style="margin-left: 1px;">
                            </div>
                            <div v-if="this.differenceScore > 0" style="margin-left: 4px;display: flex;width: 100%;">
                                <span>您比其他考生平均正确率高</span><span style="padding-left: 6px;color: #00D261;">
                                    {{ this.differenceScore }}%
                                </span>
                                <img src="../../assets/exam/heroicons-solid_trending-up.png" style="margin-left: 1px;">
                            </div>
                        </div>

                        <div
                            style="width: 307px;height: 66px;border-radius: 12px;background-color: #F4F8FF;margin: auto;margin-top: 11px;display:flex;align-items:center;">
                            <div style="width: 110px;height: 44px;top: 11px;margin-left: 20px;">
                                <span style="font-size: 14px;">
                                    {{ ((this.examResp.eu.rateCorrect * 100).toFixed(0)) + '%' }}
                                </span>
                                <br>
                                <span style="color: rgba(18, 25, 37, 0.7);font-size: 11px;">本次模拟考试正确率</span>
                            </div>

                            <hr
                                style="height: 16px;margin-left: 15px;margin-right: 0px;color: rgba(167, 177, 192, 0.5);">

                            <div style="width: 110px;height: 44px;top: 11px;margin-left: 20px">
                                <span style="font-size: 14px;">
                                    {{ ((this.examResp.exam.rateCorrect * 100).toFixed(0)) + '%' }}
                                </span>
                                <br>
                                <span style="color: rgba(18, 25, 37, 0.7);font-size: 11px;">该考试平均正确率</span>
                            </div>
                        </div>

                        <div style="width: 317px;height: 167px;margin: 12px auto;display:flex;justify-content:center;align-items:center;"
                            ref="ringChartContainer">
                            <!-- <div  style="width: 138px; height: 138px;"></div> -->
                        </div>



                        <hr style="width: 331px;height: 0px;border-top:1px solid #E6E6E6;">


                        <div style="margin-top: 42px;margin-left: 29px;display: flex;">
                            <img src="../../assets/exam/Vector (1).png" style="height: 20px;width: 20px;">
                            <div v-if="this.differenceTimeRawFlag == false" style="margin-left: 4px;display: flex;">
                                <span>您比其他考生平均少用时</span><span style="padding-left: 6px;color: #F46363;">
                                    {{ this.differenceTime }}
                                </span>
                                <img src="../../assets/exam/heroicons-solid_trending-down.png"
                                    style="margin-left: 1px;">
                            </div>
                            <div v-if="this.differenceTimeRawFlag == true" style="margin-left: 4px;display: flex;">
                                <span>您比其他考生平均多用时</span><span style="padding-left: 6px;color: #00D261;">
                                    {{ this.differenceTime }}
                                </span>
                                <img src="../../assets/exam/heroicons-solid_trending-up.png" style="margin-left: 1px;">
                            </div>
                        </div>

                        <div
                            style="width: 307px;height: 66px;border-radius: 12px;background-color: #F4F8FF;margin: auto;margin-top: 11px;display:flex;align-items:center;margin-top: 20px;">
                            <div style="width: 110px;height: 44px;top: 11px;margin-left: 20px;">
                                <span style="font-size: 14px;">
                                    {{ this.myExamTime }}
                                </span>
                                <br>
                                <span style="color: rgba(18, 25, 37, 0.7);font-size: 11px;">本次模拟考试时长</span>
                            </div>

                            <hr
                                style="height: 16px;margin-left: 15px;margin-right: 0px;color: rgba(167, 177, 192, 0.5);">

                            <div style="width: 140px;height: 44px;top: 11px;margin-left: 20px">
                                <span style="font-size: 14px;">
                                    {{ this.examTimeAvg }}
                                </span>
                                <br>
                                <span style="color: rgba(18, 25, 37, 0.7);font-size: 11px;">该考试其他考生平均用时</span>
                            </div>
                        </div>

                        <div style="width: 307px; height: 44px;margin: 20px auto;display:flex;position: relative;">
                            <span style="font-size: 13px;color: #7B828D;height: 18px;">考试时长</span>
                            <span style="font-size: 13px;color: #7B828D;margin-left:auto;height: 18px;">
                                {{ examTimeSum }}
                            </span>
                            <div
                                style="height: 10px; width: 305px; background: #EBEEF2; border-radius: 21px; position:absolute; left:0; bottom:0;">
                                <div :style="progressBarStyle"></div>
                            </div>
                        </div>
                    </div>


                    <!-- 右 -->
                    <div style="width: 585px;height: 652px;background-color: #FFFFFF;">
                        <div style="margin-top: 40px;margin-left: 21px;">
                            <span style="font-size: 17px;color: #FBA90A;">课程步骤分数</span>

                        </div>

                        <el-table :data="tableData" height="536px" :header-cell-style="{ background: '#F4F8FF', }"
                            style="margin-top: 15px;margin-left: 21px;width: 546px;border: 1px solid #E6E6E6;border-radius: 10px; overflow-x: auto;">
                            <el-table-column prop="question.name" label="步骤"
                                :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="question.question" label="操作项"
                                :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="question.score" label="得分"
                                :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="question.remark" label="操作说明"
                                :show-overflow-tooltip="true"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>



        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>

    </div>


</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { examReportApi } from "@/api/exam/exam";
import { myCenterApi } from '@/api/myCenter/myCenter'
import * as echarts from "echarts";
export default {
    components: {
        bottomComponent,
        headComponent,
    },
    created() {
        document.title = "模拟考试报告";
        this.examReport();
        this.mycenter();
    },
    mounted() {
        // 在数据加载完成后，再初始化 Echarts
        this.$watch("examResp", (newVal) => {
            if (newVal && newVal.eu && newVal.exam) {
                this.initEcharts();
                this.initRingChart();
            }
        });
    },
    data() {
        return {
            my: {
                approveIdCard: "",
                approveTeacher: "",
                name: "",
                gender: "",
                mobile: "",
                profile: "",
                orgId: "",
                professionType: "",
                professionTypeId: "",
                idCardNo: "",
                userId: ""
            },
            tableData: [],
            examResp: {},
            //差值正确率
            differenceScore: 0,
            //差值时间
            differenceTime: "",
            //时间差
            differenceTimeRaw: 0,
            //原时间差正负
            differenceTimeRawFlag: true,
            //个人时间
            myExamTime: "",
            //本次平均时间
            examTimeAvg: "",
            //考试总时长
            examTimeSum: "",
            examDuration: 0, // 存储考试总时长
            euDuration: 0,// 存储考生用时
            progressBarStyle: ''
        };
    },
    methods: {
        mycenter() {
            myCenterApi().then(resp => {
                this.my = resp.data.data
            })
        },
        // 考试报告
        //exam 总考试 eu 个人
        examReport() {
            examReportApi(this.$route.query.id).then((resp) => {
                this.tableData = resp.data.data.answerList;
                this.examResp = resp.data.data;
                this.differenceScore = (resp.data.data.eu.rateCorrect - resp.data.data.exam.rateCorrect) * 100;  //个人减平均
                this.differenceScore = parseFloat(this.differenceScore).toFixed();
                console.log("相差正确率" + this.differenceScore)
                // 打印考生用时和考试平均用时
                // console.log("考生用时：", resp.data.data.eu.duration);
                // console.log("考试平均用时：", resp.data.data.exam.durationAvg);

                // 计算时间差
                this.differenceTimeRaw = resp.data.data.eu.duration - resp.data.data.exam.durationAvg;

                // console.log("时间差原始值：", this.differenceTimeRaw);

                if (this.differenceTimeRaw < 0) {
                    this.differenceTimeRawFlag = false
                    this.differenceTimeRaw = this.differenceTimeRaw * -1
                }

                //考试总时长
                let hoursSum = Math.floor(resp.data.data.exam.duration / (60 * 60));
                let minutesSum = Math.floor((resp.data.data.exam.duration % (60 * 60)) / 60);
                let secondsSum = Math.floor(resp.data.data.exam.duration % 60);

                let timePartSum = [];
                if (hoursSum > 0) {
                    timePartSum.push(`${hoursSum}小时`);
                }
                if (minutesSum > 0) {
                    timePartSum.push(`${minutesSum}分`);
                }
                if (secondsSum > 0) {
                    timePartSum.push(`${secondsSum}秒`);
                }


                this.examTimeSum = timePartSum.join('');


                // 将时间差转换为时分秒格式
                let hours = Math.floor(this.differenceTimeRaw / (60 * 60));
                let minutes = Math.floor((this.differenceTimeRaw % (60 * 60)) / 60);
                let seconds = Math.floor(this.differenceTimeRaw % 60);

                let timeParts = [];
                if (hours > 0) {
                    timeParts.push(`${hours}时`);
                }
                if (minutes > 0) {
                    timeParts.push(`${minutes}分`);
                }
                if (seconds > 0) {
                    timeParts.push(`${seconds}秒`);
                }

                this.differenceTime = timeParts.join('');

                // 将个人时间转换为时分秒格式
                let myHours = Math.floor(resp.data.data.eu.duration / (60 * 60));
                let myMinutes = Math.floor((resp.data.data.eu.duration % (60 * 60)) / 60);
                let mySeconds = Math.floor(resp.data.data.eu.duration % 60);

                let myTimeParts = [];
                if (myHours > 0) {
                    myTimeParts.push(`${myHours}时`);
                }
                if (myMinutes > 0) {
                    myTimeParts.push(`${myMinutes}分`);
                }
                if (mySeconds > 0) {
                    myTimeParts.push(`${mySeconds}秒`);
                }

                this.myExamTime = myTimeParts.join('');

                // 将平均时间转换为时分秒格式
                let avgHours = Math.floor(resp.data.data.exam.durationAvg / (60 * 60));
                let avgMinutes = Math.floor((resp.data.data.exam.durationAvg % (60 * 60)) / 60);
                let avgSeconds = Math.floor(resp.data.data.exam.durationAvg % 60);

                let avgTimeParts = [];
                if (avgHours > 0) {
                    avgTimeParts.push(`${avgHours}时`);
                }
                if (avgMinutes > 0) {
                    avgTimeParts.push(`${avgMinutes}分`);
                }
                if (avgSeconds > 0) {
                    avgTimeParts.push(`${avgSeconds}秒`);
                }

                this.examTimeAvg = avgTimeParts.join('');

                // console.log("最终时间差：", this.differenceTime);
                // console.log("考生用时：", this.myExamTime);
                // console.log("考试平均用时：", this.examTimeAvg);


                this.examDuration = resp.data.data.exam.duration;
                this.euDuration = resp.data.data.eu.duration;

                // 计算进度条样式
                const progressWidth = (this.euDuration / this.examDuration) * 100;
                this.progressBarStyle = `width: ${progressWidth}%; height: 10px; background: linear-gradient(to right, #FFE769, #FF8D3B, #FF3D61); border-radius: 21px; position:absolute; left:0; bottom:0;`;

            });
        },



        // 分数统计图
        initEcharts() {
            const myChart = echarts.init(this.$refs.echartsContainer);
            const score = this.examResp.eu.score;
            const totalScore = this.examResp.exam.score;
            const data = [
                { value: score, name: "得分", labelLine: { show: true }, itemStyle: { color: 'rgba(55, 123, 254, 0.8)' } }, // 设置得分部分颜色为 #377BFE 透明度 0.2
                { value: totalScore - score, name: "未得分", itemStyle: { color: 'rgba(55, 123  , 254, 0.2)' } }, // 设置未得分部分颜色为 #377BFE 透明度 0.8
            ];
            const option = {
                series: [
                    {
                        type: "pie",
                        radius: ["100%", "90%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        labelLine: {
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: data,
                    },
                ],
                textStyle: {
                    fontSize: 14,
                    fontWeight: "bold",
                },
                graphic: [
                    {
                        type: "text",
                        left: "center",
                        top: "center",
                        style: {
                            text: score,
                            fill: "#6097FF",
                            fontSize: 80,
                        },
                    },
                    {
                        type: "text",
                        left: "160",
                        top: "105",
                        style: {
                            text: "分",
                            fill: "#7B828D",
                            fontSize: 16,
                        },
                    },
                ],
            };
            myChart.setOption(option);
        },


        //错误率统计图
        initRingChart() {
            const ringChart = echarts.init(this.$refs['ringChartContainer']);
            const correctRate = Math.round(this.examResp.eu.rateCorrect * 100);  // 四舍五入保留整数 
            const incorrectRate = 100 - correctRate;
            const data = [
                { value: correctRate, name: '正确', itemStyle: { color: 'rgba(46, 118, 254, 1)' } },
                { value: incorrectRate, name: '错误', itemStyle: { color: 'rgba(253, 169, 5, 1)' } },
                { x: 0, y: correctRate },
                { x: 1, y: incorrectRate }
            ];
            const option = {
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '75%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: function (params) {  // 修正后的格式化函数
                                if (params.name === '正确') {
                                    return '正确率' + correctRate + "%";
                                } else if (params.name === '错误') {
                                    return '错误率' + incorrectRate + "%";
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: true
                            }
                        },
                        data: data,
                        labelLine: {
                            show: true,
                            type: 'polyline',
                            polylinePoints: [
                                [0.55, 0.55],
                                [1.8, 1.8],
                                [1.8, 0.7]
                            ],
                            lineStyle: {
                                width: 2,
                                color: data[0].itemStyle.color
                            }
                        },
                    },
                ],
                textStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                },
            };
            ringChart.setOption(option);
        }
    },


};
</script>


<style></style>
