// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 首页函数接口
export const indexPageApi = () => {
  return service({
    url: "/front/api/home/index",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
  });
};
