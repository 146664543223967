import { encode } from "js-base64";
import { rsa_encode } from "../../src/utils/rsa";
export const sign = (token, key) => {
  // 当前时间戳
  const now = Date.now(); // 加密内容： 当前时间戳与token拼接
  const text = `${now}${token}`;

  const timeBase64 = encode(now);
  const signStr = rsa_encode(text, key); // 签名使用rsa加密
  return encode(signStr, timeBase64);
};
