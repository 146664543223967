<template>

    <div style="display: flex; ; min-height: 98vh; background-color: #F8F9FC;">

        <div style="flex: 1; overflow-y: auto;">

            <el-breadcrumb separator="/" style="margin: 5% auto 0;width: 83%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/setting' }">设置</el-breadcrumb-item>
                <el-breadcrumb-item>问题反馈</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="width: 83%;margin: 4% auto auto auto;border-radius: 12px">
                <div
                    style="width: 452px;height: 476px;margin: auto;margin-top: 46.73px;box-shadow: 0px 2px 6px 0px #00000026;padding: 10px 50px 20px;background-color: white;">
                    <el-tabs style="display: grid; justify-content: center" :stretch="true">
                        <el-form ref="form" :model="form">


                            <el-form-item label="内容反馈" style="margin-top: 29px">
                                <el-input type="textarea" placeholder="请填写反馈" v-model="form.content"
                                    style="width: 400px;font-family:"></el-input>
                            </el-form-item>

                            <el-form-item label="联系邮箱" style="margin-top: 29px">
                                <el-input type="input" placeholder="请输入联系邮箱" v-model="form.email"
                                    style="width: 400px"></el-input>
                            </el-form-item>

                            <el-form-item style="margin-top: 50px">
                                <el-button type="primary" @click="feedBack"
                                    style="width: 400px;margin: auto;">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import { feedBackApi } from '@/api/setting/setting'
export default {
    mounted() {

    },

    created() {
        document.title = "问题反馈";
    },
    data() {
        return {
            form: {

            }
        };
    },

    methods: {
        feedBack() {
            feedBackApi(this.form.content, this.form.email).then(resp => {
                console.log(resp)
            })
        }
    }


}
</script>

<style>
body {
    display: block;
    margin: 0px;
}

.el-input::placeholder {
    font-family: "Microsoft YaHei", sans-serif !important;
    /* 你可以替换为你想要的字体 */
    font-size: 14px;

    font-weight: 400;
    /* 根据需要调整字体大小 */
}
</style>
