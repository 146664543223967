// file:request.js
// 1、引入axios库
import axios from "axios";

// 2、创建axios实例
const service = axios.create({
  baseURL: "", // api的base_url
  timeout: 10000, // 请求超时时间
});

// 3、请求拦截器
service.interceptors.request.use(
  (config) => {
    console.log("request url=" + config.url);

    // 获取token，如果有token，就放入http的请求头里面

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 4、响应拦截器
service.interceptors.response.use(
  (response) => {
    // 定时刷新token
    // console.log(response.data);
    return response;
  },
  (error) => {
    //发生错误时的响应

    return Promise.reject(error);
  }
);

export default service;
