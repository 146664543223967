<template>
  <div style="background-color: #f8f9fc;width: 100%;">
    <!-- 头部 -->
    <headComponent>
    </headComponent>

    <!-- 轮播图 -->
    <div style="">
      <el-carousel style="width: 100%;max-width: 1920px;aspect-ratio: 2.5;margin: 0 auto;" height="100%">
        <el-carousel-item v-for="(item, index) in indexForm.bannerList" :key="index"
                          style="width: 100%;max-width: 1920px;aspect-ratio: 2.5;">
          <img :src="item.cover" alt="" @click="toCourse(item.id)"
               style="width: 100%;max-width: 1920px;aspect-ratio: 2.5;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="notification-div">
      <div style="width: 780px" v-if="newsContent.length > 0">
        <div class="notification-title">
          <div class="title" style="font-size: 24px;">新闻资讯</div>
          <div style="flex: 1"></div>
          <div class="more pointer" style="font-size: 12px" @click="toMsg(2)">查看更多</div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div class="news pointer"
               style="position:relative;width: 380px;height: 230px;overflow:hidden;background-color: var(--body-bg-color)"
               @click="detailPage(newsContent[0].id)">
            <img style="object-fit: cover" width="100%" height="100%"
                 :src="newsContent[0].cover"/>
            <div
                class="ellipsis-two"
                style="box-sizing: border-box;position: absolute;bottom: 0;background-color: #3D3D3D80;color: white;width: 100%;padding:4px;">
              {{ newsContent[0].name }}
            </div>
          </div>
          <div style="flex: 1"></div>
          <div class="news pointer"
               style="position:relative;width: 380px;height: 230px;overflow:hidden;background-color: var(--body-bg-color)"
               @click="detailPage(newsContent[1].id)">
            <img style="object-fit: cover" width="100%" height="100%"
                 :src="newsContent[1].cover"/>
            <div
                class="ellipsis-two"
                style="box-sizing: border-box;position: absolute;bottom: 0;background-color: #3D3D3D80;color: white;width: 100%;padding:4px;">
              {{ newsContent[1].name }}
            </div>
          </div>
        </div>

        <div class="pointer" style="display: flex;margin-top: 24px" v-for="(newsContents, index) in newsContent"
             :key="newsContents.id"
             @click="detailPage(newsContents.id)" v-if="index < 3">
          <div style="text-align: center;flex:0 0 auto;">
            <div style="font-size: 38px">{{ newsContents.updateTime.substring(8, 10) }}</div>
            <div style="font-size: 18px"> {{ newsContents.updateTime.substring(0, 10) }}</div>
          </div>
          <div style="margin-left: 10px;">
            <div style="font-size: 16px;">{{ newsContents.name }}</div>
            <div style="font-size: 14px;color: var(--content-text-color);margin-top: 12px; " class="ellipsis-two">
              {{ newsContents.summary }}
            </div>
          </div>
        </div>
      </div>

      <div style="width: 380px;margin-left: 44px">
        <div class="notification-title">
          <div class="title" style="font-size: 24px;">通知公告</div>
          <div style="flex: 1"></div>
          <div class="more pointer" style="font-size: 12px;margin: 0 10px" @click="toMsg(1)">查看更多</div>
        </div>
        <div style="padding:10px 0 ">
          <div class="pointer" style="margin-top: 10px;" v-for="(noticeContents, index) in noticeContent"
               :key="noticeContents.id"
               @click="detailPage(noticeContents.id)" v-if="index < 8">
            <div style="font-size: 16px;" class="ellipsis-two">
              {{ noticeContents.name }}
            </div>
            <div style="padding-top:6px;font-size: 14px;color: var(--content-text-color);">
              {{ noticeContents.updateTime.slice(0, 10) }}
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- 课程 -->
    <div id="course-div">
      <!-- 精选课程 -->
      <div id="jx-course-div">
        <div style="display: flex;">
          <div style="height: 30px;width: 3px;background-color: #377bfe;"/>
          <span style="font-size: 24px; line-height:30px;font-weight: bold;margin-left: 10px;">精选课程</span>
          <div style="flex: 1"></div>
          <div style="width: 66px; height: 20px; display: flex; align-items: center; justify-content: center;">
            <el-link
                style="display: flex; align-items: center; height: 20px; padding: 0 5px; box-sizing: border-box; font-size: 14px; color: #7B828D;"
                :underline="false" href="/#/course">
              <span style="vertical-align: middle;">更多</span>
              <img src="../../assets/myCenter/arrow.png"
                   style="width: 20px; height: 20px; margin-left: 0; vertical-align: middle; " alt=""/>
            </el-link>
          </div>
        </div>

        <div class="course-div">
          <div class="pointer course-item" v-for="(course, index) in this.indexForm.courseList.slice(0, 8)"
               :key="index"
               @click="courseDetail(course.id)">
            <div style="overflow: hidden" class="news">
              <img width="100%" :src="course.logo" style="object-fit: fill;aspect-ratio: 16/9" alt=""/>
            </div>
            <div style="padding: 10px">
              <span class="ellipsis-two"
                    style="font-size: 16px;font-weight:bold;line-height: 29.8px;text-align: left;">
                {{ course.name }}
              </span>
              <div style="">
                <span style=" font-size:14px;color: var(--content-text-color);">{{ course.orgName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 热门课程 -->
      <div id="jx-course-div" style="margin-top: 40px">
        <div style="display: flex;">
          <div style="height: 30px;width: 3px;background-color: #377bfe;"/>
          <span style="font-size: 24px; line-height:30px;font-weight: bold;margin-left: 10px;">热门课程</span>
          <div style="flex: 1"></div>
          <div style="width: 66px; height: 20px; display: flex; align-items: center; justify-content: center;">
            <el-link
                style="display: flex; align-items: center; height: 20px; padding: 0 5px; box-sizing: border-box; font-size: 14px; color: #7B828D;"
                :underline="false" href="/#/course">
              <span style="vertical-align: middle;">更多</span>
              <img src="../../assets/myCenter/arrow.png"
                   style="width: 20px; height: 20px; margin-left: 0; vertical-align: middle; " alt=""/>
            </el-link>
          </div>
        </div>
        <div class="course-div">
          <div class="pointer course-item" v-for="(course, index) in this.indexForm.hotCourseList.slice(0, 8)" :key="index"
               @click="courseDetail(course.id)">
            <div style="overflow: hidden" class="news">
              <img width="100%" :src="course.logo" style="object-fit: fill;aspect-ratio: 16/9" alt=""/>
            </div>
            <div style="padding: 10px">
              <span class="ellipsis-two"
                    style="font-size: 16px;font-weight:bold;line-height: 29.8px;text-align: left;">
                {{ course.name }}
              </span>
              <div style="">
                <span style=" font-size:14px;color: var(--content-text-color);">{{ course.orgName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 48px;">
        <div style="text-align: center;font-size: 24px;font-weight: bold;">
          试点建设单位
        </div>
        <div class="partner-container">
          <div v-for="item in partners">
            <img :src="item"/>
          </div>
        </div>
        <!--        <div style="position: relative;margin-top: 24px;">-->
        <!--          <div ref="scrollContainer" style="display: flex; flex-wrap: nowrap; overflow-x: hidden;">-->
        <!--            <div v-for="(school, index) in schools" :key="index" class="yx-course1-div">-->
        <!--              <img-->
        <!--                  style="width: 30px;height:30px;object-fit: cover;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);margin-top: 10px;"-->
        <!--                  :src="school.license" alt="School Logo"/>-->
        <!--              <span style="font-size: 15px; margin-top: 8px;">{{ school.name }}</span>-->
        <!--            </div>-->
        <!--          </div>-->


        <!--          <el-button class="big-icon-button"-->
        <!--                     style="position: absolute; top: 50%; left: 0; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.5); border: none; outline: none;"-->
        <!--                     @click="scrollLeft">-->
        <!--            <div class="change-icon">-->
        <!--              <i class="el-icon-arrow-left"></i>-->
        <!--            </div>-->
        <!--          </el-button>-->


        <!--          <el-button-->
        <!--              style="position: absolute; top: 50%; right: 0; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.5); border: none; outline: none;"-->
        <!--              @click="scrollRight">-->
        <!--            <div class="change-icon">-->
        <!--              <i class="el-icon-arrow-right"></i>-->
        <!--            </div>-->
        <!--          </el-button>-->
        <!--        </div>-->
      </div>
    </div>

    <bottomComponent>
    </bottomComponent>
  </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import {indexPageApi} from '@/api/index/indexApi'
import {selectedCoursesApi} from '@/api/course/course'
import {schoolListApi} from '@/api/schoolList/schoolList'
import {noticeApi, newsApi} from '@/api/message/message'

export default {
  components: {
    bottomComponent,
    headComponent,
  },
  created() {
    document.title = "智慧模拟医学网";
    this.index();
    //精选课程
    this.selectedCourses();
    //院校
    this.schoolList();
    //公告
    this.noticeList();
    //新闻
    this.newsList();
  },
  data() {
    return {
      indexForm: {
        bannerList: [],
        courseList: [],
        hotCourseList:[]
      },
      input: "",
      schools: [],
      selectedCoursesList: [],
      pageNo: 1,
      carouselHeight: '50vh',
      noticeLists: {},
      noticeContent: [],

      newsLists: {},
      newsContent: [],

      messageLists: {},
      messageContent: [],

      isDataLoaded: false,
      partners: [
        "https://f.medsimmeta.com/partners/中国医院协会.png",
        "https://f.medsimmeta.com/partners/中国科学院深圳先进技术研究院.png",
        "https://f.medsimmeta.com/partners/北京大学第三医院.png",
        "https://f.medsimmeta.com/partners/湖南省人民医院.png",

        "https://f.medsimmeta.com/partners/北京大学口腔医学院.png",
        "https://f.medsimmeta.com/partners/华西医院.png",
        "https://f.medsimmeta.com/partners/浙江大学邵逸夫医院.png",
        "https://f.medsimmeta.com/partners/上海东方医院.png",

        "https://f.medsimmeta.com/partners/广州医科大学附属第一医院.png",
        "https://f.medsimmeta.com/partners/重庆儿童医院.png",
        "https://f.medsimmeta.com/partners/东南大学附属中大医院.png",
        "https://f.medsimmeta.com/partners/苏州大学附属第一医院.png",

        "https://f.medsimmeta.com/partners/首都医科大学口腔医院.png",
        "https://f.medsimmeta.com/partners/华中科技大学协和医院.png",
        "https://f.medsimmeta.com/partners/中山大学附属第一医院.png",
        "https://f.medsimmeta.com/partners/吉林大学白求恩第一医院.png",

        "https://f.medsimmeta.com/partners/广西医科大学第一附属医院.png",
        "https://f.medsimmeta.com/partners/复旦大学附属儿科医院.png",
        "https://f.medsimmeta.com/partners/温州医科大学附属第一医院.png",
        "https://f.medsimmeta.com/partners/中国福利会国际和平妇幼保健院.png",

        "https://f.medsimmeta.com/partners/桂林医学院附属医院.png",
        "https://f.medsimmeta.com/partners/中南大学湘雅三医院.png",
        "https://f.medsimmeta.com/partners/赣南医科大学第一附属医院.png",
        "https://f.medsimmeta.com/partners/上海交通大学医学院附属新华医院.png",

        "https://f.medsimmeta.com/partners/右江民族医学院附属医院.png",
        "https://f.medsimmeta.com/partners/同济大学医学院.png",
        "https://f.medsimmeta.com/partners/智慧模拟医学网.png",
        "https://f.medsimmeta.com/partners/虚位以待.png",

      ]

    };
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer;
      container.scrollLeft -= container.offsetWidth / 2;
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      container.scrollLeft += container.offsetWidth / 2;
    },

    //多出30个字省略
    truncateText(text) {
      if (text.length > 30) {
        return text.slice(0, 30) + '...';
      }
      return text;
    },

    //新闻公告详情页
    detailPage(id) {
      // console.log(id)
      this.$router.push("/messageCenter/detailPage?id=" + id)
    },
    //公告
    noticeList() {
      noticeApi(this.pageNo).then(resp => {
        this.noticeLists = resp.data.data;
        this.noticeContent=[...this.noticeContent,...resp.data.data.content];
      })
    },
    //新闻
    newsList() {
      newsApi(this.pageNo).then(resp => {
        this.newsLists = resp.data.data;
        this.newsContent = resp.data.data.content;
        this.noticeContent=[...this.noticeContent,...resp.data.data.content];
      })
    },

    //精选课程
    selectedCourses() {
      selectedCoursesApi(this.pageNo).then(resp => {
        this.selectedCoursesList = resp.data.data.content
        // console.log(this.selectedCoursesList)
      })
    },

    //院校
    schoolList() {
      schoolListApi("").then(resp => {
        this.schools = resp.data.data;
      });
    },

    //去课程页面
    toCourse(id) {
      this.$router.push("/messageCenter/detailPage?id=" + id)
    },

    //消息页面
    toMsg(index) {
      this.$router.push("/messageCenter/msgCenter?index=" + index)
    },


    index() {
      indexPageApi().then(resp => {
        this.indexForm = resp.data.data
      })
    },
    handleResize() {
      // 根据窗口大小计算轮播图的高度
      this.carouselHeight = Math.min(window.innerHeight * 0.5, 500); // 例如设置为窗口高度的一半，但不超过 500px
    },

    courseDetail(id) {
      this.$router.push(`/courseDetail?id=${id}`)
    },

  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    // 在组件销毁前移除窗口大小变化的监听
    window.removeEventListener('resize', this.handleResize);
  },


};
</script>

<style scoped>
.course-item {
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFF;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
}

.notification-div {
  box-sizing: border-box;
  width: 1240px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 30px 18px;
  background-color: white;
}

.notification-title {
  display: flex;
}

.notification-title .title {
  font-size: 24px;
}

.notification-title .more {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: var(--content-text-color);
}

#notic1 {
  background-color: #eff5ff;
  float: left;
  width: 48%;
  height: 164px;
}

#notic2 {
  background-color: #eff5ff;
  float: right;
  width: 48%;
  height: 164px;
}

#course-div {
  width: 1240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
}


.xk-course-div {
  width: 100%;
  height: 338px;
  margin-top: 48px;
}


.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.yx-course1-div {
  width: 10%;
  border-radius: 16px;
  /* height: 100%; */
  background: linear-gradient(to bottom, #eafffb, white);
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  flex: 0 0 auto;
  /* Remove scrollbar */
  overflow: hidden;
}


.yx-course1-div span {
  font-size: 15px;
  margin-top: 8px;
  display: inline-block;
  text-align: center;
}


button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-text {
  color: #a7b1c0;
  font-size: 12px;
  margin: 0 8px 0 8px;
}

.span1 {
  height: 27px;
  width: 38px;
  font-size: 19px;
  color: #121925;
  margin-left: 18px;
}

.span2 {
  color: #7b828d;
  font-size: 14px;

  padding: 18px
}

.noticeContent:hover {
  color: #5a21c4;
  cursor: pointer;
}

div[style="background-color: #f8f9fc;width: 100%;"] {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* 使用弹性布局来确保元素自适应 */
.container {
  display: flex;
  flex-wrap: wrap;
}

/* 将图片的宽度设置为相对值 */
img {
  width: 100%;
}

.change-icon {
  font-size: 20px;
}

.ellipsis-two {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news img {
  transition: all 0.6s;
}

.news img:hover {
  transform: scale(1.05);

}

.course-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 18px;
  margin-top: 30px;
}

.partner-container {
  margin: 36px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 18px;
}

.partner-container > div {
  height: 107px;
  background-color: white;
  border-radius: 10px;
}
</style>
