<template>

    <div class="app-wrapper" style="display: flex; ; min-height: 98vh; background-color: #F8F9FC;">

        <div style="flex: 1; overflow-y: auto;">

            <el-breadcrumb separator="/" style="margin: 5% auto 0;width: 83%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/setting' }">设置</el-breadcrumb-item>
                <el-breadcrumb-item>版本信息</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="width: 83%;margin: 4% auto auto auto;border-radius: 12px">

                <div style="margin: auto;display: grid; align-items: center;justify-content: center;">
                    <img style="width: 100px;height:100px;margin: auto;" src="../../assets/logo/logo1.png">
                    <span style="margin-top: 10%;">智慧模拟医学网</span>
                    <span style="margin:auto; margin-top: 10%;color:#7B828D;">V1.0.0</span>
                </div>

            </div>

        </div>
    </div>
</template>


<script>
// import { AboutUsApi } from '@/api/setting/setting'
export default {
    mounted() {

    },

    created() {
        document.title = "版本信息";
    },
    data() {
        return {

        };
    },

    methods: {

    }


}
</script>

<style>
body {
    display: block;
    margin: 0px;
}
</style>
