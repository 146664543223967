import CryptoJS from "crypto-js";

class CryptoUtil {
  //加密方法
  encrypt(text) {
    const secretKey = CryptoJS.enc.Utf8.parse("!(ZJyN5*+RcA(jiW");
    const symKey = CryptoJS.AES.encrypt(text, secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return symKey.toString();
  }

  //解密方法
  decrypt(text) {
    const secretKey = CryptoJS.enc.Utf8.parse("!(ZJyN5*+RcA(jiW");
    const symKey = CryptoJS.AES.decrypt(text, secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return symKey.toString(CryptoJS.enc.Utf8);
  }
}

export default new CryptoUtil();
