<template>
    <div id="div">
        <div style="padding: 30px;">
            <span>
                <h1 style="text-align: center;">联系我们</h1>
                我们随时欢迎您向我们提出产品、服务或合作方面的建议并与我们开展相关讨论。 请将您的想法告诉我们！
                <br><br>
                <img src="../assets/logo/image.png">&nbsp &nbsp 邮箱：mixlab@siat.ac.cn
            </span>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        document.title = "联系我们";
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped>
#div {
    background-color: rgb(248, 249, 252);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.bottom-text {
    color: #a7b1c0;
    font-size: 12px;
    margin: 0 8px 0 8px;
}
</style>