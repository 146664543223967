<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>
          <div style="width:1240px;margin: 0 auto;">
            <el-breadcrumb separator="/" style="margin: 24px auto 0;width: 100%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item>学习记录</el-breadcrumb-item>
            </el-breadcrumb>


            <div style="font-size: 32px;color: rgba(18, 25, 37, 1);margin: 40px auto 0;width: 100%;">
                学习记录
            </div>


            <div style="margin: 16px auto 0; width: 100%;padding-bottom: 1%;">
                <div>
                    <el-input placeholder="搜索课程" style="width: 264px;" suffix-icon="el-icon-search"></el-input>
                </div>
                <div style="width: 100%;margin: auto; display: flex; flex-direction: column;margin-top: 24px;">
                    <div v-for="(row, rowIndex) in getRows()" :key="rowIndex"
                        style="display: flex; margin-bottom: 24px;">
                        <div v-for="(exam, colIndex) in row" :key="colIndex" style="margin-right: 24px;">
                            <div
                                style="width: 384px;height: 180px;display: grid;background-color: #FFFFFF; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);border-radius: 8px;">
                                <span style="font-size: 14px;color: #A7B1C0;padding-left: 16px;padding-top: 10px;">
                                    · {{ formatExamDate(exam.learnStartTime) }}
                                </span>
                                <span style="padding-left: 16px;padding-top: 12px;">
                                    <!-- <el-tag
                                style="font-weight: 400;background-color: #ECF2FF;color: #377BFE;border-color: #ECF2FF;font-size: 14;">标签一</el-tag> -->
                                    <span style="font-size: 15px;color: #121925;font-weight: 500;padding-left: 4px">
                                        {{ exam.course.name }}
                                    </span>
                                </span>
                                <!-- <span style="font-size: 14px;color: #7B828D;padding-left: 16px;padding-top: 8px;">
                                用时：{{ formatDuration(exam.duration) }}
                            </span>
                            <span style="font-size: 14px;color: #7B828D;padding-left: 16px;padding-top: 8px;">
                                答对数：{{ exam.countQuestionRight }}
                            </span> -->
                                <div
                                    style="display: flex; justify-content: flex-end;padding-right: 16px;padding-bottom: 10px;">
                                    <el-button
                                        style="background-color: #ECF2FF;color: #377BFE;border-color: #ECF2FF;height: 32px; width: 70px;font-size: 14px;padding: 6px 14px;"
                                        type="success" @click="courseDetail(exam.courseId)">去练习</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="this.myStudy.length <= 0">
                    <el-empty description="暂无数据" />
                </div>

                <div class="block" style="margin: 32px auto 0 auto;width: 515px; text-align: center;">
                    <el-pagination background @current-change="handleCurrentChange" :current-page="pageNo" page-size="7"
                        layout="prev, pager, next, total, jumper" :total="totalElements">
                    </el-pagination>
                </div>
            </div>




        </div>
        </div>

        <bottomComponent>
            <template #footer>
            </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from '../bottomComponent.vue';
import headComponent from '../headComponent.vue';
import { myStudyApi } from "@/api/study/study";

const PAGE_WIDTH = 1200;

export default {
    created() {
        document.title = "学习记录";
        // this.courseCollectList()
        // this.examList();
        this.studyList();
    },
    components: {
        bottomComponent,
        headComponent
    },
    data() {
        return {
            activeName: 'first',
            tabIndex: 1,
            exams: [],
            courses: {},
            pageNo: 1,
            myStudy: [],
            totalElements: 0,
            pageSize: 15
        };
    },
    methods: {
        handleTabClick(index) {
            this.tabIndex = index;
        },
        // courseCollectList() {
        //   courseCollectApi(this.pageNo, "COLLECT").then(resp => {
        //     this.courses = resp.data.data.content
        //   })
        // },
        studyList() {
            console.log(2222)
            myStudyApi(this.pageNo, this.pageSize).then(resp => {
                console.log(111111111111)
                console.log(resp)
                this.totalElements = resp.data.data.length
                this.myStudy = resp.data.data;
            });
        },

        courseDetail(id) {
            this.$router.push('/courseDetail?id=' + id)
        },

        getRows() {
            const rows = [];
            const limitedExams = this.myStudy.slice((this.pageNo - 1) * 7, this.pageNo * 7);
            for (let i = 0; i < limitedExams.length; i += 3) {
                rows.push(limitedExams.slice(i, i + 3));
            }
            return rows;
        },
        // examList() {
        //   examListApi(this.pageNo).then((resp) => {
        //     this.exams = resp.data.data.content;
        //   });
        // },
        formatExamDate(dateStr) {
            const date = new Date(dateStr);
            return `${date.getMonth() + 1}月${date.getDate()}日`;
        },
        formatDuration(duration) {
            const minutes = Math.floor(duration / 60);
            const seconds = duration % 60;
            return `${minutes}分${seconds}秒`;
        },
        // 查看报告
        viewReport(id) {
            this.$router.push("/examReport?id=" + id);
        },
        handleCurrentChange(page) {
            this.pageNo = page;
            this.getRows();
        }
    }
};
</script>

<style scoped>
.div1 {
    float: left;
    margin-left: 20px;
    height: 260px;
    width: 224px;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.div3 {
    height: 40px;
    width: 192px;
    margin: 12px 16px 0 16px;
}

.tab-box {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
    margin: 16px auto 0;
    width: 1200px;
}

.tab-item {
    padding: 5px;
    text-align: center;
    margin-right: 40px;
    cursor: pointer;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.jx1-course-div {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 280px;
    width: 285px;
    margin-top: 24px;
    float: left;
    cursor: pointer;
}
</style>