// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 发送验证码
export const sendCodeApi = (mobile) => {
  return service({
    url: "front/api/account/sms/send",
    method: "post",
    data: {
      mobile,
    },
  });
};

//检查用户输入的手机号验证码是否正确
export const checkApi = (mobile, code) => {
  return service({
    url: "front/api/account/sms/check",
    method: "post",
    data: {
      mobile,
      code,
    },
  });
};
