<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <!-- 头部组件，可以根据需要添加或修改内容 -->
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>

            <el-breadcrumb separator="/" style="margin: 24px auto 0;width: 83%;">
                <!-- 面包屑导航 -->
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item>缓存课程</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="font-size: 32px;color: rgba(18, 25, 37, 1);margin: 40px auto 0;width: 83%;">
                课程缓存
            </div>

            <div style="width: 83%; margin: auto; display: flex; flex-wrap: wrap;padding-bottom: 1%;">

            </div>
        </div>

        <bottomComponent>
            <!-- 底部组件 -->
            <template #footer>
                <!-- 可以在此处添加或修改底部内容 -->
            </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { courseCache } from "@/api/course/course";

export default {
    created() {
        document.title = "课程缓存";
        this.fetchCourses();
    },

    components: {
        bottomComponent,
        headComponent,
    },

    data() {
        return {
            courses: [], // 假设courses是一个数组  
            pageNo: 1
        };
    },

    methods: {
        courseDetail(id) {
            // 处理点击课程详细信息的逻辑  
            console.log('Clicked course with ID:', id);
        }
    }
}  
</script>

<style></style>