// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 2、定义验证码登录函数接口
export const loginApi = (mobile, code) => {
  return service({
    url: "/front/api/account/login/by/mobile",
    method: "post",
    data: {
      mobile,
      code,
    },
  });
};

//手机号验证码登录，没有账号就注册
export const codeLoginApi = (mobile, code) => {
  return service({
    url: "/front/api/account/login/by/mobile",
    method: "post",
    data: {
      mobile,
      code,
    },
  });
};

//手机号密码登录
export const passwordLoginApi = (mobile, password) => {
  return service({
    url: "/front/api/account/login/by/pwd",
    method: "post",
    data: {
      mobile,
      password,
    },
  });
};

//检查手机号是否注册
export const existApi = (mobile) => {
  return service({
    url: "front/api/account/mobile/exist",
    method: "post",
    data: {
      mobile,
    },
  });
};
