<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>
          <div style="width:1240px;margin: 0 auto;">
            <el-breadcrumb separator="/" style="margin: 24px auto 0;width: 100%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item>模拟考试记录</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="font-size: 32px;color: rgba(18, 25, 37, 1);margin: 40px auto 0;width: 100%;">
                模拟考试记录
            </div>

            <div
                style="width: 100%;margin: auto; display: flex; flex-direction: column;margin-top: 24px;padding-bottom: 1%;">
                <div v-for="(row, rowIndex) in getRows()" :key="rowIndex" style="display: flex; margin-bottom: 24px;">
                    <div v-for="(exam, colIndex) in row" :key="colIndex" style="margin-right: 24px;width: 31%;">
                        <div
                            style="width: 100%;height: 180px;display: grid;background-color: #FFFFFF; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);border-radius: 8px;">
                            <span style="font-size: 14px;color: #A7B1C0;padding-left: 16px;padding-top: 10px;">
                                · {{ getFormattedDate(exam.startTime) }}
                            </span>
                            <span style="padding-left: 16px;padding-top: 12px;">
                                <!-- <el-tag
                    style="font-weight: 400;background-color: #ECF2FF;color: #377BFE;border-color: #ECF2FF;font-size: 14;">标签一</el-tag> -->
                                <span style="font-size: 15px;color: #121925;font-weight: 500;padding-left: 4px">
                                    {{ exam.courseName }}
                                </span>
                            </span>
                            <span style="font-size: 14px;color: #7B828D;padding-left: 16px;padding-top: 8px;">
                                用时：{{ formatDuration(exam.duration) }}
                            </span>
                            <!-- <span style="font-size: 14px;color: #7B828D;padding-left: 16px;padding-top: 8px;">
                            答对数：{{ exam.countQuestionRight }}
                                     </span> -->
                            <div
                                style="display: flex; justify-content: flex-end;padding-right: 16px;padding-bottom: 10px;">
                                <el-button
                                    style="background-color: #ECF2FF;color: #377BFE;border-color: #ECF2FF;height: 32px; width: 70px;font-size: 14px;padding: 1% 1%;"
                                    type="success" @click="viewReport(exam.id)">查看报告</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.exams.length <= 0">
                    <el-empty description="暂无数据" />
                </div>
                <div class="block" style="margin: 32px auto 0 auto;width: 515px; text-align: center;">
                    <el-pagination background @current-change="handleCurrentChange" :current-page="pageNo"
                        :page-size="12" layout="prev, pager, next, total, jumper" :total="totalElements">
                    </el-pagination>
                </div>
            </div>
        </div>
        </div>
        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { examListApi } from "@/api/exam/exam";

export default {
    components: {
        bottomComponent,
        headComponent,
    },

    created() {
        document.title = "模拟考试记录";
        this.examList();
    },

    data() {
        return {
            exams: [],
            pageNo: 1,
            totalElements: 0,
            pageSize: 12
        };
    },

    methods: {
        getRows() {
            if (!this.exams) return [];
            const rows = [];
            const limitedExams = this.exams.slice(0, 14);
            for (let i = 0; i < limitedExams.length; i += 3) {
                rows.push(limitedExams.slice(i, i + 3));
            }
            return rows;
        },
        examList() {
            examListApi(this.pageNo).then((resp) => {
                this.exams = resp.data.data.content;
                this.totalElements = resp.data.data.totalElements;
                console.log(resp);
            });
        },
        getFormattedDate(dateStr) {
            const date = new Date(dateStr);
            return `${date.getMonth() + 1}月${date.getDate()}日`;
        },
        formatDuration(duration) {
            const hours = Math.floor(duration / 3600);
            const remainingSeconds = duration % 3600;
            const minutes = Math.floor(remainingSeconds / 60);
            const seconds = remainingSeconds % 60;
            let result = '';
            if (hours > 0) {
                result += `${hours}时`;
            }
            if (minutes > 0) {
                result += `${minutes}分`;
            }
            if (seconds > 0 || result === '') {
                result += `${seconds}秒`;
            }
            return result;
        },

        // 查看报告
        viewReport(id) {
            this.$router.push("/examReport?id=" + id);
        },
        handleCurrentChange(page) {
            this.pageNo = page;
            this.examList();
        }
    },
};
</script>

<style></style>