<template>
    <div>
        <headComponent>
            <template #head>
                <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
            </template>
        </headComponent>




        <div style="width:1240px; margin: 24px auto 0;">
            <el-breadcrumb separator="/" style="">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>全部课程</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="font-size: 32px;color: rgba(18, 25, 37, 1); margin: 24px 0 0 0;">
                全部课程
            </div>
        </div>


        <div style="margin: 16px auto 0; width:1240px;">
            <div style="width: 100%;">
                <div style="display: flex; align-items: flex-start;">
                    <el-radio v-model="selectedMainDepartment" :label="selectedMainDepartment === '全部' ? null : '0'"
                        @change="handleMainDepartmentChange(selectedMainDepartment === '全部' ? null : null)"
                        style="font-size: 13px; border: none;">全部</el-radio>
                    <div style="flex: 1; display: flex; flex-wrap: wrap;">
                        <el-radio v-model="selectedMainDepartment" :label="department.id"
                            @change="handleMainDepartmentChange(department.id)"
                            style="font-size: 13px; border: none; margin-top: 0; margin-left: 10px;"
                            v-for="department in departmentList" :key="department.id">{{ department.name }}</el-radio>
                    </div>
                </div>
                <div style="margin-top: 12px;"
                    v-if="selectedMainDepartment && selectedMainDepartment !== '全部' && getSubDepartments(selectedMainDepartment).length > 0">
                    <div style="display: flex; align-items: center;">
                        <el-radio v-model="selectedSubDepartment" :label="selectedSubDepartment === '全部' ? null : '0'"
                            @change="handleSubDepartmentChange(selectedSubDepartment === '全部' ? null : null)"
                            style="font-size: 13px; border: none;">全部</el-radio>
                        <div style="flex: 1; display: flex; flex-wrap: wrap;">
                            <el-radio v-model="selectedSubDepartment" :label="subDepartment.id"
                                @change="handleSubDepartmentChange(subDepartment.id)"
                                style="font-size: 13px; border: none; margin-top: 0; margin-left: 10px;"
                                v-for="subDepartment in getSubDepartments(selectedMainDepartment)"
                                :key="subDepartment.id">{{
                                    subDepartment.name }}</el-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 1240px;margin: 0 auto;">
          <div class="course-div">
            <div class="pointer course-item" v-for="(course, index) in courses" :key="index"
                 @click="courseDetail(course.id)">
              <div style="overflow: hidden" class="news">
                <img width="100%" :src="course.logo" style="object-fit: fill;aspect-ratio: 16/9" alt=""/>
              </div>
              <div style="padding: 10px">
              <span class="ellipsis-two"
                    style="font-size: 16px;font-weight:bold;line-height: 29.8px;text-align: left;">
                {{ course.name }}
              </span>
                <div style="">
                  <span style=" font-size:14px;color: var(--content-text-color);">{{ course.orgName }}</span>
                </div>
              </div>
            </div>


          </div>
            <div v-if="this.courses.length <= 0">
                <el-empty description="暂无数据" />
            </div>

            <div class="block" style="margin: 32px auto 32px auto;width: 515px; text-align: center;">
                <el-pagination background @current-change="handleCurrentChange" :current-page="1" page-size="15"
                    layout="prev, pager, next, total, jumper" :total="this.totalElements">
                </el-pagination>
            </div>
        </div>

        <bottomComponent>
            <template #footer></template>

        </bottomComponent>
    </div>
</template>
<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { departmentListApi } from "@/api/schoolList/schoolList";
import { courseListApi } from "@/api/course/course";
export default {
    components: {
        bottomComponent,
        headComponent,
    },
    data() {
        return {
            selectedMainDepartment: "全部", // 默认选中全部
            selectedSubDepartment: "全部", // 默认小分类也选中全部
            departmentList: [],
            pageNo: 1,
            name: "",
            courses: [],
            totalElements: 0,
        };
    },
    created() {
        document.title = "全部课程";
        this.getDepartmentList();
        this.name = this.$route.query.name;
        this.courseList();
    },
    methods: {
        // 多出 30 个字省略
        truncateText(text) {
            if (text.length > 30) {
                return text.slice(0, 30) + "...";
            }
            return text;
        },
        courseDetail(id) {
            this.$router.push(`/courseDetail?id=${id}`);
        },
        handleCurrentChange(val) {
            courseListApi(
                val,
                this.name,
                this.selectedMainDepartment ? this.selectedMainDepartment.id : null,
                this.selectedSubDepartment ? this.selectedSubDepartment.id : null,
                16
            ).then((resp) => {
                this.courses = resp.data.data.content;
                this.totalElements = resp.data.data.totalElements;
                window.scrollTo(0,0,)
            });
        },
        getSubDepartments(mainDepartmentId) {
            const mainDepartment = this.departmentList.find(
                (department) => department.id === mainDepartmentId
            );
            return mainDepartment ? mainDepartment.subList : [];
        },
        handleMainDepartmentChange(id) {
            this.selectedSubDepartment = "全部"; // 点击大分类时，小分类默认选中全部
            if (id === "全部") {
                return;
            }
            courseListApi(
                this.pageNo,
                this.name,
                id,
                this.selectedSubDepartment ? this.selectedSubDepartment.id : null,
                16
            ).then((resp) => {
                this.courses = resp.data.data.content;
                this.totalElements = resp.data.data.totalElements;
            });
            if (this.getSubDepartments(id).length > 0) {
                // 如果有子分类，更新子分类列表
            } else {
                // 如果没有子分类，清空子分类列表
                this.selectedSubDepartment = null;
            }
        },
        handleSubDepartmentChange(id) {
            // 在这里处理小分类点击的逻辑，并可以使用传递的 id
            courseListApi(
                this.pageNo,
                this.name,
                this.selectedMainDepartment ? this.selectedMainDepartment.id : null,
                id,
                16
            ).then((resp) => {
                this.courses = resp.data.data.content;
            });
        },
        // 分类
        getDepartmentList() {
            departmentListApi().then((response) => {
                this.departmentList = response.data.data;
            });
        },
        // 列表
        courseList() {
            courseListApi(
                this.pageNo,
                this.name,
                this.selectedMainDepartment ? this.selectedMainDepartment.id : null,
                this.selectedSubDepartment ? this.selectedSubDepartment.id : null,
                16
            ).then((resp) => {
                this.courses = resp.data.data.content;
                this.totalElements = resp.data.data.totalElements;
            });
        },
    },
};
</script>

<style>
.el-radio__input.is-checked+.el-radio__label {
    border: 1px solid #409EFF;
    padding: 5px 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: none;
}

.el-radio__inner {
    display: none !important;
}

.el-radio {
    padding-left: 0;
    line-height: 30px !important
}

.xk-course-div {
    width: 83%;
    height: 338px;
    margin-top: 48px;
}

.jx1-course-div {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 280px;
    width: 23% !important;
    margin-top: 24px !important;
    float: left;
}

.jx2-course-div {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 280px;
    width: 380px;
    margin-left: 20px;
    margin-top: 30px !important;
    float: left;
}

.el-radio-group>.el-radio:nth-child(1) {
    margin-top: 0;
}
.news img {
  transition: all 0.6s;
}

.news img:hover {

  transform: scale(1.05);

}

.course-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 18px;
  margin-top: 30px;
}
.course-item {
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFF;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
}
</style>
