import service from "../request";

//修改密码
export const changePwdApi = (mobile, code, password, auth) => {
  return service({
    url: "front/api/user/reset",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
      auth: auth,
    },
    data: {
      mobile,
      code,
      password,
    },
  });
};

//关于我们
export const AboutUsApi = () => {
  return service({
    url: "http://bgm.medsimmeta.com/html/about.html",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
  });
};

//问题反馈
export const feedBackApi = (content, email) => {
  return service({
    url: "front/api/feedback/add",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      content,
      email,
    },
  });
};
