// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

//模拟考试记录
export const examListApi = (pageNo) => {
  return service({
    url: "/front/api/user/exam/history",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      pageNo,
    },
  });
};

//考试报告
export const examReportApi = (id) => {
  return service({
    url: "/front/api/user/exam/report",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      id,
    },
  });
};
