import JSEncrypt from "jsencrypt";

// 假设这里的公钥是从某个配置文件或者环境变量等获取的，这里先硬编码示例
const PUBLIC_KEY = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6pX7runNE6GuMMqqLpExU811mRODkN9+zzvUYvM5hZR1EGcYLriD9dCXyJvxlErbG2poB2hIDxdkIQB+1m3BnmG39N5T+rYctiVRaAt7bcmTmsFV6SPLX0X4aunU8E4w8+pdR/ahi91rMC+6Ryf6jYmx3PjW3uY5NBXIzmmGPrfPG4wcJ1VEYG/i3bEehyP2QkM+SsNMzihjF+nCfB0lY0uGsPbUkH0M9Q0E7hmfbiD95Bbio/e7UEsOjRkMAuYe0wDMfOeTL62lmlzxM0RT41+D5wp3TyBpuxbJK0R+seC9aAY1RTwwxzm53zBHMAtP1zPSEatPYalpmX+aZGAiyQIDAQAB`;

export const rsa_encode = (msg, publicKey) => {
  const jsentrypt = new JSEncrypt();
  jsentrypt.setPublicKey(publicKey || PUBLIC_KEY);
  return jsentrypt.encrypt(msg);
};

export const rsa_decode = (msg, privateKey) => {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  var decryptMsg = decrypt.decrypt(msg);
  return decryptMsg;
};
