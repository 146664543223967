<template>
    <div style="background-color: #F8F9FC;">

        <headComponent>
            <template #head>
                <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
            </template>
        </headComponent>
        <div style="height: 747px;width: 1160px;margin: 23px auto ;">
            <div style="display:flex;justify-content:space-between;align-items:center;height:50px;margin:auto;">
                <span style="color:#121925;font-size:32px;">实验室预约</span>
                <div style="display:flex;align-items:center;">
                    <img class="img" src="../../assets/subscribe/Vector.png" />
                    <span style="color:#377BFE;font-size:17px;margin-left:6px;">我的预约</span>
                </div>
            </div>

            <div
                style="width: 980px;height: 670px;top: 168px;left: 181px;gap: 0px;border-radius: 12px ;background-color:#FFFFFF;margin: 32px auto ;">
                <div style="width: 490px;height: 490px;">
                    <el-calendar v-model="value" style="">
                    </el-calendar>
                </div>
            </div>

        </div>
        <bottomComponent>
            <template #footer>
            </template>
        </bottomComponent>

    </div>
</template>

<script>
import bottomComponent from '../bottomComponent.vue';
import headComponent from '../headComponent.vue'

export default {
    data() {
        return {
            value: new Date()
        }
    },
    created() {
        document.title = "预约实验室";
    },
    components: {
        bottomComponent,
        headComponent
    },
}

</script>