<template>
    <div id="div">
        <div style="padding: 50px;">

            <span>
                <h1 style="text-align: center;">隐私协议</h1>
                <p>更新日期：2024年09月1日</p>
                <p>生效日期：2024年09月1日</p>
                <div class="section">
                    <p>智慧模拟医学网（以下或称“我们”）非常注重保护用户（“您”）的个人信息及隐私，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们希望通过本隐私政策向您清晰地介绍在使用我们的产品\服务时，我们如何处理您的个人信息，以及我们为您的提供的访问、更正、删除和保护这些信息的方式。
                    </p>
                    <p>本政策将帮助您了解以下内容：</p>
                    <ul>
                        <p>（2）<span>我们如何收集和使用您的个人信息；</span></p>
                        <p>（3）<span>我们如何使用 Cookie和同类技术；</span></p>
                        <p>（4）<span>我们如何共享、转让、公开披露您的个人信息；</span></p>
                        <p>（5）<span>我们如何保存和保护您的个人信息；</span></p>
                        <p>（6）<span>您如何管理您的个人信息；</span></p>
                        <p>（7）<span>通知和修订；</span></p>
                        <p>（8）<span>如何联系我们；</span></p>
                    </ul>
                    <p><strong
                            class="highlight">【特别提示】请您在使用我们提供的各项产品\service时，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您使用或继续使用我们的产品\service时，即意味着您同意我们按照本隐私政策处理您的相关信息。</strong>
                    </p>
                    <p>智慧模拟医学网所有的产品\service（包括智慧模拟医学网提供的产品\service及未设独立隐私政策的智慧模拟医学网关联方提供的产品\service）均适用本隐私政策。</p>
                    <p>“智慧模拟医学网的产品\service”是指智慧模拟医学网网络及技术服务提供者通过下述途径向您提供的产品\service：包括但不限于智慧模拟医学网web网站、智慧模拟医学网安卓APP、智慧模拟医学网Windows
                        PC电脑端等。</p>
                    <p>此外，针对某些特定的产品\service，我们还将制定特定的隐私政策，并在向您提供这些特定的产品\service之前加以说明。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。
                    </p>
                    <p>请您注意，本政策不适用于您通过我们的产品\service而接入的其他第三方产品\service(“其他第三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者)，具体规定请参照该第三方的隐私政策或类似声明。
                    </p>
                </div>
                <div class="section">
                    <h2>一、我们如何收集和使用您的个人信息</h2>
                    <p>我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用服务过程中主动提供或产生的个人信息:帮助您成为我们的用户，向您提供产品\service，为您优化用户体验，为您提供安全保障:
                    </p>
                    <div class="subsection">
                        <h3>（一）帮助您成为我们的用户</h3>
                        <p>为遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，在您注册成为智慧模拟医学网用户时，您需要至少提供手机号码以创建智慧模拟医学网账号，并完善相关的网络身份识别信息（如头像、单位、科室信息、专科信息、昵称及登录密码等）；如果您仅需使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。
                        </p>
                    </div>
                    <div class="subsection">
                        <h3>（二）向您提供产品\service</h3>
                        <p>1. 您向我们提供的个人信息</p>
                        <p>虚拟内容的学习和模拟考试：您在智慧模拟医学网的各个内容端进行虚拟内容的浏览、视频学习、模拟练习、模拟考试等。</p>
                        <p>问题反馈：您在使用智慧模拟医学网时，发现网站功能错误、虚拟内容的操作步骤、理论知识等错误，可以在反馈页面进行反馈。</p>
                        <p>2. 我们在您使用服务过程中可能收集的个人信息</p>
                        <p>为了满足法律法规及提供服务的基本要求，保障您的账号安全与系统运行安全，我们会收集您在使用我们服务过程中产生的相关信息，包括：</p>
                        <p>（一）日志信息。当您使用我们提供的产品\service时，我们会自动收集您使用我们服务的详细情况，并作为有关的网络日志保存，包括但不限于您的IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、及您请求的网页记录等信息。
                        </p>
                        <p>（二）其他用途</p>
                        <p>我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。</p>
                        <p>（三）请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
                        <p>1、与国家安全、国防安全有关的；</p>
                        <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                        <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                        <p>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                        <p>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
                        <p>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
                        <p>7、根据您的要求签订合同所必需的；</p>
                        <p>8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或送到与服务的故障；
                        </p>
                        <p>9、为合法的新闻报道所必需的；</p>
                        <p>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
                        <p>11、法律法规规定的其他情形。</p>
                        <p>请注意，无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将无法与任何特定个人建立联系的数据与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将为视为个人信息。
                        </p>
                    </div>
                    <div class="subsection">
                        <h3>（三）其他用途</h3>
                        <p>我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。</p>
                    </div>
                </div>
                <div class="section">
                    <h2>二、我们我们如何使用 Cookie和同类技术</h2>
                    <p>（一）Cookie的使用</p>
                    <p>为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端\system上存储名为 Cookie的小数据文件。
                        Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程;为您提供安全购物的偏好设置;帮助您优化对广告的选择与互动;保护您的数据安全等。
                        我们不会将Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有
                        Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie;另外，您也可以清除软件内保存的所有Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
                    </p>
                </div>
                <div class="section">
                    <h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
                    <p>（一）共享</p>
                    <p>1、我们不会与智慧模拟医学网以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
                    <p>（1）事先获得您明确的同意或授权；</p>
                    <p>（2）基于法定情形下：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求；</p>
                    <p>请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行去标识化处理。其中您的联络信息和地址信息属于个人敏感信息，我们已经采取了“默认号码保护”、“地址隐藏”等举措尽全力保护您的个人信息。

                    </p>
                    <p>（2）基于法定情形下：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求；</p>
                    <p>请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行去标识化处理。其中您的联络信息和地址信息属于个人敏感信息，我们已经采取了“默认号码保护”、“地址隐藏”等举措尽全力保护您的个人信息。
                    </p>
                    <p>对我们仅为实现本政策中声明的目的与之共享个人信息的公司、衬织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
                </div>
                <div class="section">
                    <h2>四、我们如何保存和保护您的个人信息</h2>
                    <p>（一）个人信息的保存</p>
                    <p>1、保存期限：除非依据法律法规或双方约定，我们仅会在实现目的所必需的最低时间内留存您的相关个人信息。在您主动注销账号时，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理，有关注销账号的相关规则和流程，请参见本政策附件中的《用户注销协议》。
                    </p>
                    <p>2、保存地域：原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中国境内，但以下情形除外：</p>
                    <p>法律法规有明确规定的；</p>
                    <p>单独征得您的授权同意；</p>
                    <p>您主动发起的跨境预定、下单、交易等个人行为（如购买国际机票、预订国际酒店等）。</p>
                    <p>在上述情形中，我们会并要求数据接收方按照本隐私政策以及其他相关的安全保密措施来处理个人信息。</p>
                    <p>3、终止运营：如果发生终止运营等情形，我们将会至少提前30天通知您，并在终止运营后对您的个人信息进行删除或匿名化处理。</p>
                    <p>1、数据安全措施</p>
                    <p>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
                    </p>
                    <p>智慧模拟医学网通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如在您的浏览器与“服务”之间交换数据(如信用卡信息)时采用SSL加密保护技术;同时对网站本身提供https安全浏览方式;使用加密技术确保数据的保密性;使用受信赖的保护机制防止数据遭到恶意攻击;部署访问控制机制，确保只有授权人员才可访问个人信息;以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                    </p>
                    <p>2、安全认证</p>
                    <p>我们已先后通过了公安部信息安全等级保护三级认证、第三方支付行业（支付卡行业PCI
                        DSS)数据安全标准国际认证、ISO27001信息安全管理体系国际认证等认证资质，并与监管机构、第三方测评机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。
                    </p>
                    <p>23、请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。
                    </p>
                    <p>34、安全事件</p>
                    <p>在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
                    </p>
                </div>
                <div class="section">
                    <h2>五、您如何管理您的个人信息</h2>
                    <p>您对您的个人信息享有以下权利:</p>
                    <p>（一）您有权访问、更正、删除您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式管理您的信息:</p>
                    <p>账户信息——您可以访问、更正您账户中的个人资料及账号信息（身份认证信息除外）、账号绑定信息等，也可以更改您的个人密码、添加安全信息或关闭您的账户等，您可以通过访问网页及App在设置中执行此类操作。
                    </p>
                    <p>如果您无法通过上述链接管理这些个人信息，您可以随时联系我们的客服。我们将在30天内回复您的访问请求。</p>
                    <p>（二）注销账户</p>
                    <p>您有权注销您的智慧模拟医学网账户，您可以通过访问智慧模拟医学网App页面上的“我的”—“设置”——“注销账号”中进行在线操作；</p>
                </div>
                <div class="section">
                    <h2>六、通知和修订</h2>
                    <p>为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。未经您明确同意，我们不会削减您依据本隐私政策所应享的权利。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。
                    </p>
                    <p>如果您不同意修订后的政策，您有权停止使用我们的服务。我们会尊重您的决定，并按照原政策继续处理您的个人信息直至您停止使用服务或者我们根据法律法规或双方约定需要对您的个人信息进行其他处理。</p>
                </div>
                <div class="section">
                    <h2>七、如何联系我们</h2>
                    <p>如果您对于我们的个人信息处理行为存在任何投诉举报需求，您可以通过智慧模拟医学网上提供的联系方式与我们联系并作充分描述，我们将在验证您身份的30天内答复您的请求并尽力解决。</p>
                    <p>如果您对我们的回复不满意，特别是认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼。</p>
                </div>
            </span>

        </div>
    </div>
</template>

<script>
export default {
    created() {
        document.title = "隐私协议";
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
}


h2 {
    margin-bottom: 10px;
}

h2 {
    font-size: 20px;
    font-weight: bold;
}

p {
    margin-bottom: 15px;
}

.highlight {
    font-weight: bold;
}

.section {
    margin-bottom: 30px;
}

.subsection {
    margin-bottom: 20px;
}


ul p {
    margin-bottom: 3px;
    /* 调整段落间距 */
}

ul span {
    text-decoration: underline;
}
</style>