// 院校合计
// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 我的学习
export const myStudyApi = (pageNo, pageSize) => {
  return service({
    url: "front/api/user/course/study/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      pageSize,
    },
  });
};
