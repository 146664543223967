// 院校合计
// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

//院校合集
export const schoolListApi = (name) => {
  return service({
    url: "front/api/org/search",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      name,
    },
  });
};

//查询全部专业
export const departmentListApi = (name) => {
  return service({
    url: "front/api/pro/all",
    method: "post",
    headers: {
      "Access-Control-Allow-Origin": "https://bgm.medsimmeta.com/",
      token: localStorage.getItem("token"),
      debug: "false",
      plat: "web",
    },
    data: {
      name,
    },
  });
};
