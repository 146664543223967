<template>
  <div style="background-color: #f8f9fc">
    <headComponent>
      <template #head>
        <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
      </template>
    </headComponent>

    <div style="width: 83%;margin: auto;">
      <el-breadcrumb separator="/" style="margin: 24px 0 ">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
        <el-breadcrumb-item>编辑资料</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="
        width: 83%;
        margin: 42px auto ;
        border-radius: 12px;
        background-color: #ffffff;
        padding-bottom: 42px;
      " class="card-content">
      <div class="left">
        <!-- <img v-if="!my.profile == null" :src="my.profile"
          style="width: 80px; height: 80px; object-fit: cover; border-radius: 50%;" /> -->
        <img v-if="my.profile == null" src="../../assets/myCenter/headPortrait.png"
          style="width: 120px; height: 120px; display: block; object-fit: cover; border-radius: 50%;" class="avatar" />


        <img v-if="my.profile != null" style="width: 120px; height: 120px; display: block" :src="my.profile"
          class="avatar" />
        <!-- <el-button type="primary" plain class="edit-btn" @click="dialogVisible = true">编辑头像</el-button> -->
      </div>
      <div>
        <p style="font-size: 24px; font-weight: bold">基本信息</p>
        <el-form ref="form" :model="form" label-width="80px" label-position="top" style="width: 600px">
          <el-form-item label="用户名">
            <el-input v-model="my.name"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="my.gender" placeholder="请选择" style="width: 600px">
              <el-option v-for="(option, index) in [
                { label: '男', value: 1 },
                { label: '女', value: 2 }
              ]" :key="index" :label="option.label" :value="option.value" :selected="my.gender === option.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院">


            <el-select v-if="my.orgId" v-model="my.orgId" placeholder="请选择" style="width: 600px">
              <el-option v-for="(school, index) in schools" :key="index" :label="school.name" :value="school.id"
                :selected="school.id === my.orgId"></el-option>
            </el-select>

            <el-select v-if="!my.orgId" v-model="my.orgName" placeholder="请选择" style="width: 600px">
              <el-option v-for="(school, index) in schools" :key="index" :label="school.name" :value="school.id"
                :selected="school.id === my.orgId"></el-option>
            </el-select>


          </el-form-item>


          <el-form-item label="科室">
            <el-select v-model="my.professionTypeId" placeholder="请选择" style="width: 600px">
              <el-option v-for="(departments, index) in departmentList" :key="index" :label="departments.name"
                :value="departments.id"
                :selected="departments.id === my.professionTypeId && my.professionTypeId === ''">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="联系方式">
            <el-input v-model="my.mobile"></el-input>
          </el-form-item>
        </el-form>
        <!-- <p style="font-size: 24px; font-weight: bold">个人认证</p> -->
        <!-- <el-button type="text">去申请认证</el-button> -->
        <div style="margin-top: 30px">
          <el-button type="primary" @click="saveMyeditInfo">确认保存</el-button>
        </div>
      </div>
    </div>

    <!-- 更改头像dialog -->
    <el-dialog title="更改头像" :visible.sync="dialogVisible" width="30%">
      <!-- 图片上传 -->
      <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar1">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <bottomComponent>
      <template #footer> </template>
    </bottomComponent>
  </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { myCenterApi, updateMyInfoApi } from '@/api/myCenter/myCenter'
import { schoolListApi, departmentListApi } from '@/api/schoolList/schoolList'

export default {
  components: {
    bottomComponent,
    headComponent,
  },

  data() {
    return {
      my: {
        approveIdCard: "",
        approveTeacher: "",
        name: "",
        gender: "",
        mobile: "",
        profile: "",
        orgId: "",
        professionType: "",
        professionTypeId: "",
        idCardNo: "",
        userId: ""
      },
      form: {
        name: "",
        gender: "",
        profile: "",
        orgId: "",
        professionTypeId: "",
      },
      schools: [],

      // 科室信息集合
      departmentList: [],
      dialogVisible: false,
      imageUrl: ''
    };
  },

  created() {
    document.title = "编辑资料";
    this.mycenter()
    this.schoolList()
    this.getDepartmentList()
  },

  methods: {

    saveMyeditInfo() {
      for (let key in this.form) {
        if (this.my.hasOwnProperty(key)) {
          this.form[key] = this.my[key];
        }
      }

      if (this.form.orgId == null) {
        //不修改医院名称
        console.log("不修改医院名称")
        updateMyInfoApi(this.form.name, this.form.gender, this.form.profile, this.form.orgId, this.form.professionTypeId).then(resp => {
          console.log(resp)
          if (resp.data.code == 0) {
            this.$message.success("修改成功")
          }
        })
      } else {
        // this.form.orgId = this.my.orgName
        updateMyInfoApi(this.form.name, this.form.gender, this.form.profile, this.form.orgId, this.form.professionTypeId).then(resp => {
          console.log(resp)
          if (resp.data.code == 0) {
            this.$message.success("修改成功")
          }
        })
      }

    },
    mycenter() {
      var token = localStorage.getItem("token")
      if (token == null || token == "") {
        alert("请先登录")
        this.$router.push("/login")
      }
      myCenterApi().then(resp => {
        this.my = resp.data.data
      })
    },
    onSubmit() {
    },

    //院校
    schoolList() {
      schoolListApi("").then(resp => {
        this.schools = resp.data.data;
        // 初始化时根据 my.orgId 进行默认选中

      });
    },

    //科室
    getDepartmentList() {
      departmentListApi().then(response => {
        this.departmentList = response.data.data;
      })
    },



    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  },

};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar1 {
  width: 178px;
  height: 178px;
  display: block;
}


.img {
  width: 27px;
  height: 27px;
  margin: 25px 0 25px 25px;
}

.imgDiv {
  width: 1306px;
  height: 76px;
  border-top: 1px ridge rgba(235, 238, 242, 1);
}

.card-content {
  display: flex;
}

.left {
  width: 30%;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  margin: 100px auto 20px;
}

.edit-btn {
  background: #fff;
  margin: 0 auto;
  display: block;
}

::v-deep .el-form-item__label {
  line-height: 16px;
}
</style>
