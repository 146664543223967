<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
:root {
  --primary-color: rgb(55, 123, 254);
  --primary-text-color: #1a1a1a;
  --content-text-color: #767676;
  --body-bg-color: #f7f7f7;
  --bottom-bg-color: #272727;
}
.pointer {
  cursor: pointer;
}
</style>
