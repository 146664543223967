<template>

    <div style="display: flex; ; min-height: 98vh; background-color: #F8F9FC;">

        <div style="flex: 1; overflow-y: auto;">

            <el-breadcrumb separator="/" style="margin: 5% auto 0;width: 83%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/setting' }">设置</el-breadcrumb-item>
                <el-breadcrumb-item>修改密码</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="width: 83%;margin: 4% auto auto auto;border-radius: 12px">
                <div
                    style="width: 452px;height: 476px;margin: auto;margin-top: 46.73px;box-shadow: 0px 2px 6px 0px #00000026;padding: 10px 50px 20px;background-color: white;">
                    <el-tabs style="display: grid; justify-content: center" :stretch="true">
                        <el-form ref="form" :model="form">
                            <span style="font-size: 14px; line-height: 40px">手机号</span>

                            <div style="width: 400px;">
                                <el-input placeholder="请输入手机号" v-model="form.mobile" style="width: 250px"></el-input>
                                <el-button type="primary" plain @click="sendCode" :disabled="counting"
                                    style="margin-left: 16px; margin-right: 0; width: 134px">
                                    {{ counting ? `${countdown} 秒` : '发送验证码' }}
                                </el-button>
                            </div>

                            <el-form-item label="验证码" style="margin-top: 29px">
                                <el-input placeholder="请输入验证码" v-model="form.code" style="width: 400px"></el-input>
                            </el-form-item>

                            <el-form-item label="密码" style="margin-top: 29px">
                                <el-input placeholder="请输入密码" v-model="form.password" style="width: 400px"></el-input>
                            </el-form-item>
                            <el-form-item style="margin-top: 50px">
                                <el-button type="primary" @click="changePwd"
                                    style="width: 400px;margin: auto;">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tabs>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import { rsa_encode } from '@/utils/rsa';
import { sign } from '@/utils/sign';
import { sendCodeApi } from '@/api/register/registerApi'
import { changePwdApi } from '@/api/setting/setting'
export default {
    mounted() {
    },

    created() {
        document.title = "修改密码";
    },
    data() {
        return {
            counting: false,
            form: {},
            countdown: 60,
        };
    },

    methods: {
        //发送验证码
        sendCode() {
            if (this.form.mobile == null || this.form.mobile == "") {
                this.$message.error("请输入手机号")
            } else {
                if (!this.validatePhone(this.form.mobile)) {
                    // 手机号码格式不正确，给出提示
                    this.$message.error('手机号码格式不正确，请重新输入');
                } else {
                    this.counting = true;
                    const timer = setInterval(() => {
                        if (this.countdown > 0) {
                            this.countdown--;
                        } else {
                            clearInterval(timer);
                            this.counting = false;
                            this.countdown = 60;
                        }
                    }, 1000);
                    sendCodeApi(this.form.mobile).then(resp => {
                        if (resp.data.code == 0) {
                            this.$message.success("发送成功")
                        } else {
                            this.$message.error("发送成功失败")
                        }
                    })
                }
            }
        },


        //校验手机号码
        validatePhone(phoneNumber) {
            const phoneRegex = /^1[3-9]\d{9}$/;
            return phoneRegex.test(phoneNumber);
        },

        //修改密码
        changePwd() {
            // 密钥
            let secretKey = localStorage.getItem("secretKey")
            // token
            let token = localStorage.getItem("token");
            let auth = sign(token, secretKey);
            let encodePassword = rsa_encode(this.form.password, secretKey)
            changePwdApi(this.form.mobile, this.form.code, encodePassword, auth).then(resp => {
                console.log(resp);
            });
        }
    }


}
</script>

<style>
.imgDiv {
    width: 100%;
    border-top: 1px ridge rgba(235, 238, 242, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
}

body {
    display: block;
    margin: 0px;
}

.imgDivTextSpan {
    font-size: 16px;
    margin-left: 16px;
}

.imgDivImgSpan {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}
</style>
