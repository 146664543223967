// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 课程筛选接口
export const courseListApi = (
  pageNo,
  name,
  professionTypeId,
  subProfessionTypeId,
  pageSize
) => {
  return service({
    url: "/front/api/course/search",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      name,
      professionTypeId,
      subProfessionTypeId,
      pageSize,
      sortBy: "countPlay",
      direction: "DESC",
      platFlagPc: "true",
    },
  });
};

// 新课推荐接口
export const newCourseListApi = (pageNo) => {
  return service({
    url: "/front/api/course/search",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      direction: "DESC",
    },
  });
};

// 精选课程接口
export const selectedCoursesApi = (pageNo) => {
  return service({
    url: "/front/api/course/search",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      featured: true,
      sortBy: "countPlay",
      direction: "DESC",
      platFlagPc: "true",
    },
  });
};

// 课程详情接口
export const courseDetailApi = (id) => {
  return service({
    url: "/front/api/course/detail",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      id,
    },
  });
};

// 课程点赞收藏评分接口
export const courseCollectAddApi = (courseId, counter, score) => {
  return service({
    url: "/front/api/course/counter/add",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      courseId,
      counter,
      score,
    },
  });
};

// 课程取消点赞收藏评分接口
export const courseCollectDeleteApi = (ids, counter) => {
  return service({
    url: "/front/api/course/counter/delete",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      ids,
      counter,
    },
  });
};

// 开始学习课程接口
export const courseStudyApi = (courseId) => {
  return service({
    url: "/front/api/user/course/study",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      courseId,
    },
  });
};

// 开始报名课程接口
export const courseSignUpApi = (courseId) => {
  return service({
    url: "/front/api/user/course/signUp",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      courseId,
    },
  });
};

//模拟考试接口
export const courseExamApi = (courseId, start) => {
  return service({
    url: "/front/api/user/course/exam",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      courseId,
      start,
    },
  });
};

//课程收藏接口
export const courseCollectApi = (pageNo, counter) => {
  return service({
    url: "/front/api/course/counter/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      pageNo,
      counter,
    },
  });
};

//课程收藏接口
export const courseCacheApi = (pageNo, counter) => {
  return service({
    url: "/front/api/course/counter/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
    data: {
      pageNo,
      counter,
    },
  });
};

//课程学习
export const courseStudysApi = (encryptedData) => {
  return service({
    url: "/front/api/manage?message=" + encryptedData,
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
    },
  });
};

//记录学习时长
export const courseWatchsApi = (courseId, duration) => {
  return service({
    url: "/front/api/user/course/watch",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "false",
      plat: "web",
    },
    data: {
      courseId,
      duration,
    },
  });
};
