import Vue from "vue";
import Router from "vue-router";
import index from "@/views/index/index.vue";
import login from "@/views/login/login.vue";
import register from "@/views/register/register.vue";
import myCenter from "@/views/myCenter/myCenter.vue";
import editInfo from "@/views/myCenter/editInfo.vue";
import detailPage from "@/views/messageCenter/detailPage.vue";
import MsgCenter from "@/views/messageCenter/msgCenter.vue";
import CourseDetail from "@/views/courseDetail/courseDetail.vue";
import study from "@/views/study/study.vue";
import report from "@/views/report/report.vue";
import course from "@/views/course/course.vue";
import courseCollect from "@/views/courseCollect/courseCollect.vue";
import courseCache from "@/views/courseCache/courseCache.vue";
import exam from "@/views/exam/exam.vue";
import examReport from "@/views/exam/examReport.vue";
import subscribe from "@/views/subscribe/subscribe.vue";
import setting from "@/views/setting/setting.vue";
import AboutUs from "@/views/AboutUs.vue";
import changePassword from "@/views/setting/changePassword.vue";
import copyrightInformation from "@/views/setting/copyrightInformation.vue";
import problemFeedback from "@/views/setting/problemFeedback.vue";
import privacyAgreement from "@/views/privacyAgreement.vue";
import contactUs from "@/views/contactUs.vue";
import fillInformation from "@/views/myCenter/fillInformation.vue";
import laboratory from "@/views/laboratory/laboratory.vue";
import laboratoryDetail from "@/views/laboratory/laboratoryDetail.vue";
import healthyChinaInitiative from "@/views/healthyChinaInitiative/healthyChinaInitiative.vue";
import healthyChinaInitiativeDetail from "@/views/healthyChinaInitiative/healthyChinaInitiativeDetail.vue";
import supportAndService from "@/views/supportAndService/supportAndService.vue";
import supportAndServiceDetail from "@/views/supportAndService/supportAndServiceDetail.vue";
import forgetThePassword from "@/views/forgetThePassword/forgetThePassword.vue";

Vue.use(Router);

// eslint-disable-next-line no-unused-vars
let router;
// eslint-disable-next-line no-unused-vars
export default router = new Router({
  //首页
  routes: [
    {
      path: "/index",
      name: "index",
      component: index,
    },

    //登录
    {
      path: "/login",
      name: "login",
      component: login,
    },

    //登录
    {
      path: "/",
      name: "index",
      component: index,
    },

    //注册
    {
      path: "/register",
      name: "register",
      component: register,
    },

    //个人中心
    {
      path: "/myCenter/myCenter",
      name: "myCenter",
      component: myCenter,
    },

    //基本信息
    {
      path: "/myCenter/editInfo",
      name: "editInfo",
      component: editInfo,
    },

    {
      path: "/study",
      name: "study",
      component: study,
    },

    //公告消息
    {
      path: "/messageCenter/msgCenter",
      name: "msgCenter",
      component: MsgCenter,
    },

    //公告详情
    {
      path: "/messageCenter/detailPage",
      name: "detailPage",
      component: detailPage,
    },

    //课程详情
    {
      path: "/courseDetail",
      name: "courseDetail",
      component: CourseDetail,
    },

    //模拟考试  报告
    {
      path: "/report",
      name: "report",
      component: report,
    },

    //全部课程
    {
      path: "/course",
      name: "course",
      component: course,
    },

    //课程收藏
    {
      path: "/courseCollect",
      name: "courseCollect",
      component: courseCollect,
    },

    //课程缓存
    {
      path: "/courseCache",
      name: "courseCache",
      component: courseCache,
    },

    //模拟考试信息
    {
      path: "/exam",
      name: "exam",
      component: exam,
    },

    //查看考试报告
    {
      path: "/examReport",
      name: "examReport",
      component: examReport,
    },

    //预约实验室
    {
      path: "/subscribe",
      name: "subscribe",
      component: subscribe,
    },

    //设置
    {
      path: "/setting",
      name: "setting",
      component: setting,
    },

    //修改密码
    {
      path: "/changePassword",
      name: "changePassword",
      component: changePassword,
    },

    //关于我们

    //版权信息
    {
      path: "/copyrightInformation",
      name: "copyrightInformation",
      component: copyrightInformation,
    },
    //问题反馈
    {
      path: "/problemFeedback",
      name: "problemFeedback",
      component: problemFeedback,
    },

    // 关于我们 联系我们 帮助中心

    {
      path: "/AboutUs",
      name: "AboutUs",
      component: AboutUs,
    },

    {
      path: "/contactUs",
      name: "contactUs",
      component: contactUs,
    },

    {
      path: "/privacyAgreement",
      name: "privacyAgreement",
      component: privacyAgreement,
    },

    //登录之后填写个人信息
    {
      path: "/fillInformation",
      name: "fillInformation",
      component: fillInformation,
    },

    //虚拟实验室
    {
      path: "/laboratory",
      name: "laboratory",
      component: laboratory,
    },

    //虚拟实验室详情
    {
      path: "/laboratory/laboratoryDetail",
      name: " laboratoryDetail",
      component: laboratoryDetail,
    },

    //健康中国行
    {
      path: "/healthyChinaInitiative",
      name: "healthyChinaInitiative",
      component: healthyChinaInitiative,
    },

    //健康中国行详情
    {
      path: "/healthyChinaInitiative/healthyChinaInitiativeDetail",
      name: "healthyChinaInitiativeDetail",
      component: healthyChinaInitiativeDetail,
    },

    //支持与服务
    {
      path: "/supportAndService",
      name: "supportAndService",
      component: supportAndService,
    },

    //支持与服务详情
    {
      path: "/supportAndService/supportAndServiceDetail",
      name: "supportAndServiceDetail",
      component: supportAndServiceDetail,
    },

    //忘记密码
    {
      path: "/forgetThePassword",
      name: "forgetThePassword",
      component: forgetThePassword,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 ,  behavior: 'smooth',}
  }
});
