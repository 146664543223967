<template>
  <div style="background-color: #f8f9fc">
    <headComponent>
      <template #head>
        <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
      </template>
    </headComponent>
    <div style="width:1240px;margin: 0 auto;">
      <div style="width: 100%;margin: auto;">
        <el-breadcrumb separator="/" style="margin: 24px 0 ">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course' }">全部课程</el-breadcrumb-item>
          <el-breadcrumb-item>课程详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div style="border-radius: 12px; overflow: hidden;width: 100%;margin: auto;">
        <div style="
          width: 100%;
          background-color: #ffffff;
          padding: 30px;
          height: 100%;
          box-sizing: border-box;
          " class="head-box">

          <div style="width: 436px; height: 270px; display: block; margin-right: 30px">

            <video id="courseVideo" class="video-js" :src="videoSrc" controls preload="auto" width="100%" height="264"
                   @pause="videoPause" @ended="videoEnded"></video>

            <!-- 视频播放完报个时长 离开页面报时长  -->
          </div>

          <div style="height: 100%">
            <div style="font-weight: bold; font-size: 24px">
              {{ this.course.name }}
            </div>
            <div style="color: rgba(0, 0, 0, 0.6); margin-top: 30px; display: flex">
              <div style="margin-right: 5px">{{ this.course.countStudent }} 人学过</div>
              <el-rate :colors="['#409efe', '#409efe', '#409efe']" v-model="countScore" :disabled="true"></el-rate>
            </div>
            <p style="color: rgba(0, 0, 0, 0.6)">导师：{{ this.course.orgName }} {{ this.course.teacherName }}</p>
            <!-- <p style="color: rgba(0, 0, 0, 0.6)">课程类型：MR</p> -->
            <div style="display: flex; align-items: center">
              <!-- <el-button type="primary" style="background-color: #FFFFFF;border-color: #377BFE;color: #377BFE;" plain
                class="el-icon-download" @click="download">下载课程</el-button> -->
              <!-- <span style="color: rgba(0, 0, 0, 0.6);">课程大小：785M</span> -->
            </div>
            <div style="display: flex; align-items: center; margin-top: 30px">
              <el-button type="primary" @click="courseSignUp"
                         style="width: 160px;height: 45px;color: #377BFE;background-color: #ECF2FF;border-color: #ECF2FF;"
                         plain>立即报名
              </el-button>
              <el-button type="primary"
                         style="width: 160px;height: 45px;background-color: #377BFE;border-color: #377BFE;"
                         @click="courseStudy">课程学习
              </el-button>
              <el-button type="primary"
                         style="background-color: #FBA90A;border-color: #FBA90A;width: 160px;height: 45px;"
                         @click="courseExam">模拟考试
              </el-button>

              <div v-if="!collectTimeFlag" @click="collect" style="cursor: pointer;margin-left :16px;">
                <i style="color: orange; margin-right: 10px;" class="el-icon-star-off"></i>
                收藏
                <span style="color: rgba(0, 0, 0, 0.6)">{{ this.course.countCollect }}</span>
              </div>

              <div v-if="collectTimeFlag" @click="collectOut" style="cursor: pointer;margin-left :16px;">
                <i style="color: orange; margin-right: 10px;" class="el-icon-star-on"></i>
                取消收藏
                <span style="color: rgba(0, 0, 0, 0.6)">{{ this.course.countCollect }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="
          width: 100%;
          margin: 2px auto auto auto;
          background-color: #ffffff;
          padding: 15px 30px 0;
          height: 100%;
        ">
          <div style="
                border-bottom: 1px solid #409efe;
                width: fit-content;
                padding-bottom: 8px;
                color: #409efe;
              ">
            介绍
          </div>
        </div>
        <div style="
        width: 100%;
        margin: 42px auto auto auto;
        height: 100%;
        display: flex;
        justify-content: space-between;">
          <div style="
          background: #fff;
          border-radius: 12px;
          flex: 1;
          margin-right: 30px;
          width: 100%;
          padding: 30px;
        ">

            <span v-html="course.description"></span>

            <!-- 未登录提示 -->
            <el-dialog :visible.sync="dialogVisible" width="30%">
              <h2>请先登录</h2>
              <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="toLogin()">去登录</el-button>
            </span>
            </el-dialog>


            <!-- 下载pc端提示 -->

            <el-dialog :visible.sync="dialogVisiblePC" width="30%">
              <h2>请下载PC端</h2>
              <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisiblePC = false">取 消</el-button>
              <el-button type="primary" @click="downloadPC">确认</el-button>
            </span>
            </el-dialog>

            <!-- 下载安卓端提示 -->
            <el-dialog :visible.sync="dialogVisibleAndroid" width="30%">
              <h2>请下载安卓端</h2>
              <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleAndroid = false">取 消</el-button>
              <el-button type="primary" @click="downloadAndroid">确认</el-button>
            </span>
            </el-dialog>


            <!-- 反馈提示 -->
            <el-dialog :visible.sync="dialogVisibleFeedback" width="30%">
              <h2>课程无操作文件，请您进行问题反馈,确认进入问题反馈页</h2>
              <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleFeedback = false">取 消</el-button>
              <el-button type="primary" @click="toFeedback()">确认</el-button>
            </span>
            </el-dialog>

          </div>
        </div>
      </div>
    </div>


    <bottomComponent>
      <template #footer></template>
    </bottomComponent>
  </div>
</template>

<script>
import cryptoUtil from '@/utils/crypto';
import {
  courseDetailApi,
  courseCollectAddApi,
  courseCollectDeleteApi,
  courseSignUpApi,
  courseExamApi,
  courseWatchsApi
} from "@/api/course/course";
import {myCenterApi} from "@/api/myCenter/myCenter";
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import saveAs from 'file-saver';

export default {
  created() {
    document.title = "课程详情";
  },

  components: {
    bottomComponent,
    headComponent,
  },


  data() {
    return {
      dialogVisibleAndroid: false,
      dialogVisiblePC: false,
      dialogVisibleFeedback: false,
      dialogVisible: false,
      circleUrl: "",
      score: 3,
      countScore: 0,
      course: {},
      videoSrc: "",
      collectTimeFlag: false,
      videoDescription: null,
      videoPalyDuration: ""
    };
  },

  mounted() {
    this.courseDetail();
    window.addEventListener('beforeunload', this.openWindows);
  },


  beforeRouteLeave(to, from, next) {
    this.leave();
    next();
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.openWindows);
  },

  methods: {
    downloadPC(){
      this.dialogVisiblePC = false
      window.open("https://www.medsimmeta.com/#/supportAndService/supportAndServiceDetail?id=6","_blank")
    },
    downloadAndroid(){
      this.dialogVisibleAndroid = false
      window.open("https://www.medsimmeta.com/#/supportAndService/supportAndServiceDetail?id=6","_blank")
    },
    // 关闭页面时的逻辑
    openWindows() {
      const id = parseInt(this.$route.query.id);
      const duration = parseInt(this.videoPalyDuration);
      courseWatchsApi(id, duration).then(resp => {
        console.log("上报时长");
        console.log(resp);
      });
    },

    //离开页面触发函数
    leave() {
      // localStorage.setItem("11111", "111111")
      const id = parseInt(this.$route.query.id);
      const duration = parseInt(this.videoPalyDuration);
      courseWatchsApi(id, duration).then(resp => {
        console.log("上报时长");
        console.log(resp);
      });
    },

    //视频播放完毕
    videoEnded() {
      const videoElement = document.getElementById('courseVideo');
      const duration = videoElement.duration;
      this.videoPalyDuration = duration
      // console.log('视频播放完毕，播放时长为：', this.videoPalyDuration);
    },

    //视频暂停
    videoPause() {
      const videoElement = document.getElementById('courseVideo');
      this.videoPalyDuration = videoElement.currentTime
      console.log('当前播放时间：', this.videoPalyDuration);

    },


    toLogin() {
      this.dialogVisible = false
      this.$router.push("/login")
    },

    courseDetail() {

      courseDetailApi(this.$route.query.id).then(resp => {
        // console.log("详情")
        // console.log(resp)

        this.course = resp.data.data.course;
        // console.log(this.course)
        this.videoSrc = resp.data.data.course.videoDescription;
        let userCourse = resp.data.data.userCourse;
        if (userCourse) {
        let isCollected = userCourse.collectTime ? true : false;
        this.collectTimeFlag = isCollected;
        this.countScore = userCourse.score
         }
      }).catch(error => {
        console.error('获取课程详情失败：', error);
      });
    },


    //收藏
    collect() {
      var token = localStorage.getItem("token");
      if (token == null | token == "") {
        alert("请先登录")
        return
      }
      courseCollectAddApi(this.$route.query.id, "COLLECT").then(resp => {
        if (resp.data.code == 0) {
          this.courseDetail();
        }
      })
    },

    //取消收藏
    collectOut() {
      var token = localStorage.getItem("token");
      if (token == null | token == "") {
        alert("请先登录")
        return
      }
      courseCollectDeleteApi(this.$route.query.id, "COLLECT").then(resp => {
        if (resp.data.code == 0) {
          this.courseDetail();
        }
      })
    },


    //报名
    courseSignUp() {
      var token = localStorage.getItem("token");
      if (token == null | token == "") {
        this.dialogVisible = true
        return
      }
      courseSignUpApi(Number(this.$route.query.id)).then(resp => {
        if (resp.data.code == 3) {
          this.$message.error(resp.data.message)
        }
      })
    },


    toFeedback() {
      this.$router.push("problemFeedback")
    },


    //学习
    courseStudy() {
      // eslint-disable-next-line no-debugger
      debugger
      console.log(this.course.href)
      //判断href 是否有连接
      if (!this.course.href == null || !this.course.href == "") {
        //有链接 正常进行
        var token = localStorage.getItem("token");
        if (token == null | token == "") {
          this.dialogVisible = true
          return
        }
        courseWatchsApi(Number(this.$route.query.id), 50000).then(resp => {
          // console.log(resp);
        });
        myCenterApi().then(resp => {
          var token = localStorage.getItem("token");
          var course = this.course.id
          //课程id
          var account = localStorage.getItem("userId");
          var code = "token=" + token + "&account=" + account + "&course=" + course +
              "&api=https://www.medsimmeta.com&key=1jmh@1NG3EEgwsep%HOH6Grr*5LMlPWdX!$t8elKx$1J!f26$Y7ZYME0BWde8Kpv";
          // console.log(code)
          var encryptedData = cryptoUtil.encrypt(code);
          // console.log("加密后");
          // console.log(encryptedData);

          const url = this.course.href + '?message=' + encryptedData;
          // console.log(url)
          window.open(url);
        }).catch(error => {
        });
      } else {

        console.log("href")
        //没有连接 platFlagPc是否为真
        if (this.course.platFlagPc === true) {
          //真 提示下载pc端
          console.log("platFlagPc是真")
          this.dialogVisiblePC = true
        } else {
          //否 判断 platFlagAndorid 是否为真
          if (this.course.platFlagAndroid === true) {
            //真 提示下载Andorid端
            this.dialogVisibleAndroid = true
          } else {
            //否 进入反馈页面
            this.dialogVisibleFeedback = true
          }
        }
      }
    },

    //模拟考试
    courseExam() {
      var token = localStorage.getItem("token");
      if (token == null | token == "") {
        this.dialogVisible = true
        return
      }
      myCenterApi().then(resp => {
        var token = localStorage.getItem("token");
        var course = this.course.id
        //课程id
        var account = localStorage.getItem("userId");

        var code = "token=" + token + "&account=" + account + "&course=" + course +
            "&api=https://www.medsimmeta.com&key=1jmh@1NG3EEgwsep%HOH6Grr*5LMlPWdX!$t8elKx$1J!f26$Y7ZYME0BWde8Kpv";

        console.log("URL:"+code)
        var encryptedData = cryptoUtil.encrypt(code);
        // console.log("加密后");
        // console.log(encryptedData);

        const url = this.course.href + '?message=' + encryptedData;
        // console.log(url)
        window.open(url);
      }).catch(error => {
        console.log(error);
      });
    },

    //下载视频
    download() {
      var token = localStorage.getItem("token");
      if (token == null | token == "") {
        alert("请先登录")
        return
      }
      fetch(this.videoSrc)
          .then(response => response.blob())
          .then(blob => {
            saveAs(blob, this.course.name + '.mp4'); // 文件名可根据实际情况修改
          });
    },


  },

};
</script>

<style scoped>
.head-box {
  display: flex;
}

.btn-course {
  border: 1px solid #377bfe;
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  color: #377bfe;
  font-weight: bold;
}

.head-btn-group {
  padding: 10px 30px;
  color: #fff;
  font-size: 15px;
  border-radius: 8px;
  font-weight: bold;
  margin-right: 20px;
}

div {
  box-sizing: border-box;
}
</style>